import React, { Component } from "react";
import {
  Table,
  Divider,
  Select,
  Row,
  Col,
  Icon,
  Button,
  Tooltip,
  notification,
  Modal,
  Input,
} from "antd";
import PrintView from "./widget/PrintView";
import axios from "axios";
import preval from "preval.macro";
import { FaRegThumbsUp, FaRegThumbsDown, FaThumbsUp } from "react-icons/fa";

const { TextArea } = Input;
const { Option } = Select;
class SurveyResponse extends Component {
  state = {
    dataSource: [],
    activities: [],
    generalRecommendations: [],
    fetching: true,
    visible: false,
    selectedRecommendation: null,
    reasonToReport: "",
    reasonToReportLength: 0,
  };

  componentDidMount = async () => {
    try {
      const response = await axios.get(
        `response/recommendation/?response_id=${this.props.match.params.responseID}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: null,
          },
        }
      );

      const activities = response.data.activities;
      const currentActivity = activities.find(
        (x) => x.id === response.data.activity
      );
      const firstName = response.data.learner.name.split(" ")[0];
      const generalRecommendations = response.data.general_recommendations;
      const learner =
        firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();

      this.setState({
        currentActivity,
        dataSource: currentActivity.activityrecommendation,
        generalRecommendations,
        activities,
        learner,
        fetching: false,
      });
    } catch (e) {
      this.props.history.replace("/404");
    }
  };

  getRecommendations = (currentActivity) => {
    if (!currentActivity) return;
    return currentActivity.activityrecommendation;
  };

  onThumbsUpRecommend = async (key) => {
    const openNotification = () => {
      notification.success({
        message: "Thank you very much for your feedback",
      });
    };
    const { currentActivity } = this.state;
    try {
      await axios.get(`course/recommendation/upvote/`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: null,
        },
        params: {
          id: key,
          response_id: this.props.match.params.responseID,
        },
      });

      currentActivity.activityrecommendation.find(
        (x) => x.id === key
      ).is_upvoted = true;

      this.setState(
        {
          currentActivity,
        },
        () => openNotification()
      );
    } catch (e) {
      console.log(e);
    }
  };

  onReportBrokenLink = async () => {
    const openNotification = () => {
      notification.success({
        message: "Recommendation is successfully reported",
        description:
          "Our admin team will fix this as soon as possible. Thank you",
      });
    };
    const {
      currentActivity,
      selectedRecommendation,
      reasonToReport,
    } = this.state;

    currentActivity.activityrecommendation.find(
      (x) => x.id === selectedRecommendation.id
    ).is_broken_link = true;
    currentActivity.activityrecommendation.find(
      (x) => x.id === selectedRecommendation.id
    ).is_upvoted = false;
    this.setState({ sending: true });
    try {
      await axios.get(`course/recommendation/downvote/`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: null,
        },
        params: {
          id: selectedRecommendation.id,
          response_id: this.props.match.params.responseID,
          reason: reasonToReport,
        },
      });
    } catch (e) {
      console.log(e);
    }

    this.setState(
      {
        currentActivity,
        visible: false,
        reasonToReport: "",
        selectedRecommendation: null,
        sending: false,
      },
      () => openNotification()
    );
  };

  onChangeActivity = (id, side) => {
    const { activities, currentActivity } = this.state;
    let index;

    let newActivity;
    if (!side) {
      index = activities.findIndex((activity) => activity.id === id);
      newActivity = activities[index];
    } else {
      index = activities.findIndex(
        (activity) => activity.id === currentActivity.id
      );
      if (side === "R") {
        index = index + 1;
      } else {
        index = index - 1;
      }
    }

    newActivity = activities[index];

    this.setState({ currentActivity: newActivity });
  };

  navigationBlock = (activities, currentActivity, side) => {
    const index = activities.findIndex(
      (activity) => activity.id === currentActivity.id
    );
    let blockNavigation = "N";
    if (index === 0) {
      blockNavigation = "L";
    } else if (index === activities.length - 1) {
      blockNavigation = "R";
    }

    return blockNavigation === side;
  };

  render() {
    const {
      learner,
      fetching,
      activities,
      currentActivity,
      generalRecommendations,
      reasonToReport,
      reasonToReportLength,
      sending,
    } = this.state;

    const columns = [
      {
        title: "Title",
        dataIndex: "title",
        editable: true,
        width: "500px",
        render: (text, record) => (
          <Row>
            <Col span={8}>
              <span>{text}</span>
            </Col>
            <Col span={12}>
              <span style={{ marginLeft: "10px" }}>
                <a href={record.url} target="_blank" rel="noopener noreferrer">
                  <i
                    style={{ fontSize: "30px", color: "grey" }}
                    className="fa fa-external-link"
                  ></i>
                </a>
              </span>
            </Col>
            <Col span={4}>
              <span>
                <Tooltip placement="topLeft" title={"DownVote"}>
                  <Button
                    disabled={record.is_broken_link}
                    style={{ float: "right", marginLeft: "10px" }}
                    //onClick={() => this.onReportBrokenLink(record)}
                    onClick={() =>
                      this.setState({
                        visible: true,
                        selectedRecommendation: record,
                      })
                    }
                  >
                    <FaRegThumbsDown />
                  </Button>
                </Tooltip>

                {record.is_upvoted ? (
                  <Tooltip placement="topLeft" title={"UpVoted"}>
                    <Button
                      disabled={record.is_upvoted}
                      style={{ float: "right" }}
                      // icon="info-circle"
                      //onClick={() => this.onReportBrokenLink(record)}
                      onClick={() => this.onThumbsUpRecommend(record.id)}
                    >
                      <FaThumbsUp style={{ color: "green" }} />
                    </Button>
                  </Tooltip>
                ) : (
                  <Tooltip placement="topLeft" title={"UpVote"}>
                    <Button
                      style={{ float: "right" }}
                      disabled={record.is_broken_link}
                      // icon="info-circle"
                      //onClick={() => this.onReportBrokenLink(record)}
                      onClick={() => this.onThumbsUpRecommend(record.id)}
                    >
                      <FaRegThumbsUp />
                    </Button>
                  </Tooltip>
                )}
              </span>
            </Col>
          </Row>
        ),
      },
    ];

    return (
      <div style={{ backgroundColor: "white" }}>
        <div style={{ padding: "50px" }}>
          <div style={{ display: "flex" }}>
            <img
              style={{ height: "100px" }}
              src="https://cdn.teaching.unsw.edu.au/unswbranding/unsw_sydney.png"
              alt="UNSW Logo"
            />

            <div
              style={{
                marginLeft: "20px",
                marginTop: "25px",
                fontSize: "32px",
              }}
            >
              Learning to Teach Online
            </div>
          </div>
          <Divider style={{ borderTop: "10px solid #ffe600" }} />
          {!fetching ? (
            <>
              <Row>
                <div>
                  <h1 style={{ fontWeight: "bold" }}>
                    Personalised Resource Recommendations
                  </h1>
                </div>
                <div style={{ marginBottom: "20px", float: "left" }}>
                  <span style={{ fontSize: "20px" }}>Hello </span>
                  <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                    {learner},
                  </span>
                  <span style={{ fontSize: "20px" }}>
                    {" "}
                    please refer to your personal recommendations below based
                    upon your answers for the activities you have completed.
                  </span>
                </div>
              </Row>
              <Row style={{ marginTop: "15px" }}>
                <Col span={12}>
                  <h1>{currentActivity.module}</h1>
                </Col>
              </Row>
              <Row style={{ marginTop: "5px" }}>
                <Col span={12}>
                  <h1 style={{ fontWeight: "bold" }}>
                    {currentActivity.title} Recommendations
                  </h1>
                </Col>
                <Col span={12}>
                  <div style={{ float: "right", marginLeft: "20px" }}>
                    <Button
                      disabled={this.navigationBlock(
                        activities,
                        currentActivity,
                        "L"
                      )}
                      onClick={() => this.onChangeActivity(null, "L")}
                    >
                      &#60;
                    </Button>
                    <Button
                      disabled={this.navigationBlock(
                        activities,
                        currentActivity,
                        "R"
                      )}
                      onClick={() => this.onChangeActivity(null, "R")}
                    >
                      &#62;
                    </Button>
                  </div>
                  <div style={{ float: "right" }}>
                    <Select
                      value={currentActivity.id}
                      style={{ width: 120 }}
                      onChange={(id) => this.onChangeActivity(id)}
                    >
                      {activities.map((x) => (
                        <Option key={x.id} value={x.id}>
                          {x.title}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div>
                    <div style={{ float: "right", marginRight: "20px" }}>
                      <PrintView
                        activities={activities}
                        generalRecommendations={generalRecommendations}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Table
                  rowKey={(record) => record.id}
                  bordered
                  dataSource={this.getRecommendations(currentActivity)}
                  loading={fetching}
                  columns={columns}
                  pagination={false}
                />
              </Row>
              <Row style={{ marginTop: "50px" }}>
                <Col span={12}>
                  <h1 style={{ fontWeight: "bold" }}>
                    General Recommendations
                  </h1>
                </Col>
                <div>
                  <Table
                    rowKey={(record) => record.id}
                    bordered
                    dataSource={generalRecommendations}
                    loading={fetching}
                    columns={columns}
                    pagination={false}
                  />
                </div>
              </Row>
            </>
          ) : (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Icon type="loading" size="large" style={{ marginRight: 10 }} />
              we are setting things up for you ...
            </div>
          )}
        </div>
        <div>
          <Row
            style={{
              padding: "20px",
              backgroundColor: "#FFE600",
            }}
          >
            <Col span={12}>
              <ul>
                <li>
                  <a href="mailto:contact.pvce@unsw.edu.au">Contact us</a>
                </li>
                <li>
                  <a href="https://www.unsw.edu.au/privacy">Privacy Policy</a>
                </li>
                <li>
                  <a href="https://www.unsw.edu.au/copyright-disclaimer">
                    Copyright &amp; Disclaimer
                  </a>
                </li>
              </ul>
            </Col>

            <Col span={12}>
              <ul style={{ float: "right" }}>
                <li>
                  <div>Office of the Pro Vice-Chancellor (Education)</div>
                  <div>UNSW Sydney NSW 2052 Australia</div>
                  <div>Authorised by: Pro Vice-Chancellor (E), UNSW</div>
                  <div style={{ color: "rgba(117, 117, 117, 0.5)" }}>
                    {`Build date: ${preval`
                    const moment = require("moment");
                    module.exports = moment().format("DD/MM/YYYY");
                  `}`}
                  </div>
                </li>
              </ul>
            </Col>
          </Row>
        </div>
        <Modal
          title="Please enter your reason to report this link"
          visible={this.state.visible}
          onCancel={() => this.setState({ visible: false })}
          footer={[
            <Button
              key="back"
              onClick={() => this.setState({ visible: false })}
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              disabled={reasonToReport.length < 10}
              loading={sending}
              onClick={() => this.onReportBrokenLink()}
            >
              Submit
            </Button>,
          ]}
        >
          <div>
            <span>required characters 10 to 50 (</span>
            <span
              style={{ color: reasonToReportLength < 10 ? "red" : "green" }}
            >
              {reasonToReportLength}/50
            </span>

            <span>)</span>
            <div style={{ marginTop: "20px" }}>
              <TextArea
                placeholder="e.g.: broken link, outdated content, etc."
                onChange={({ target: { value } }) =>
                  this.setState({
                    reasonToReport: value,
                    reasonToReportLength: value.length,
                  })
                }
                value={reasonToReport}
                autoSize
              />
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default SurveyResponse;
