import React, { Component } from "react";
import { Table, Divider, Row, Col, Icon, Tag, notification, Alert } from "antd";
import axios from "axios";
import preval from "preval.macro";
import PieChart from "react-minimal-pie-chart";

class Summary extends Component {
  state = {
    dataSource: [],
    activities: [],
    completed: 0,
    inProgress: 0,
    notAttempted: 0,
    fetching: true,
    error: false,
    errorMessage: ""
  };

  openNotificationWithIcon = (type, title, description) => {
    notification[type]({
      message: title,
      description: description
    });
  };

  componentDidMount = async () => {
    try {
      const {
        data: { activity_summary: activitySummary, name }
      } = await axios.get(
        `user/summary/?token=${this.props.match.params.token}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: null
          }
        }
      );

      const firstName = name.split(" ")[0];

      const learner =
        firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();

      const completed = activitySummary.filter(
        activity => activity.summary.status === "COMPLETED"
      ).length;
      const notAttempted = activitySummary.filter(
        activity => activity.summary.status === "NOT_ATTEMPTED"
      ).length;
      const inProgress = activitySummary.filter(
        activity => activity.summary.status === "IN_PROGRESS"
      ).length;

      this.setState({
        activitySummary,
        fetching: false,
        completed,
        inProgress,
        notAttempted,
        learner
      });
    } catch (error) {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
        console.log(error.response);
        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.response.data.message
        );
        this.setState({ errorMessage: error.response.data.message });
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.request
        );
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log(
          "Error",
          "An error occurred, please try again later",
          error.message
        );
        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.message
        );
      }
      console.log(error);
      this.setState({ fetching: false, error: true });
    }
  };
  render() {
    const {
      learner,
      fetching,
      error,
      activitySummary,
      completed,
      inProgress,
      notAttempted,
      errorMessage
    } = this.state;

    const statusMap = {
      IN_PROGRESS: { label: "In Progress", color: "orange" },
      COMPLETED: { label: "Completed", color: "blue" },
      NOT_ATTEMPTED: { label: "Not Attempted", color: "green" }
    };

    const columns = [
      {
        title: "Module",
        dataIndex: "module",

        render: (text, record) => <span>{text}</span>
      },
      {
        title: "Activity",
        dataIndex: "title",

        render: (text, record) => <span>{text}</span>
      },
      {
        title: "Attempts",
        dataIndex: "summary.attempts",

        render: (text, record) => (
          <Row>
            <Col span={8}>
              {record.summary.status === "COMPLETED" ? (
                <span>{text}</span>
              ) : (
                <span>N/A</span>
              )}
            </Col>
          </Row>
        )
      },
      {
        title: "Highest Rating",
        dataIndex: "summary.rating",

        render: (text, record) => (
          <Row>
            <Col span={8}>
              {record.summary.status === "COMPLETED" ? (
                <span>{text}</span>
              ) : (
                <span>N/A</span>
              )}
            </Col>
          </Row>
        )
      },
      {
        title: "Highest Score",
        dataIndex: "summary.score",

        render: (text, record) => (
          <Row>
            <Col span={8}>
              {record.summary.status === "COMPLETED" ? (
                <span>{text}</span>
              ) : (
                <span>N/A</span>
              )}
            </Col>
          </Row>
        )
      },
      {
        title: "Status",
        dataIndex: "summary.status",

        render: (text, record) => (
          <Row>
            <Col span={8}>
              <span>
                <Tag color={statusMap[text].color}>{statusMap[text].label}</Tag>
              </span>
            </Col>
          </Row>
        )
      },
      {
        title: "Recommendations",
        dataIndex: "summary.url",

        render: (text, record) => (
          <>
            {record.summary.status === "COMPLETED" ? (
              <span
                style={{
                  alignContent: "center",

                  justifyContent: "center",
                  display: "flex",
                  marginTop: "20px"
                }}
              >
                <a href={text} target="_blank" rel="noopener noreferrer">
                  <i
                    style={{ fontSize: "30px", color: "grey" }}
                    className="fa fa-external-link"
                  ></i>
                </a>
              </span>
            ) : (
              ""
            )}
          </>
        )
      }
    ];

    return (
      <div style={{ backgroundColor: "white" }}>
        <div style={{ padding: "20px" }}>
          <div style={{ display: "flex" }}>
            <img
              style={{ height: "100px" }}
              src="https://cdn.teaching.unsw.edu.au/unswbranding/unsw_sydney.png"
              alt="UNSW Logo"
            />

            <div
              style={{
                marginLeft: "20px",
                marginTop: "25px",
                fontSize: "32px"
              }}
            >
              Learning to Teach Online
            </div>
          </div>
          <Divider style={{ borderTop: "10px solid #ffdc00" }} />
          {fetching ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Icon type="loading" size="large" style={{ marginRight: 10 }} />
              we are setting things up for you ...
            </div>
          ) : !error ? (
            <>
              <Row>
                <div>
                  <h1 style={{ fontWeight: "bold" }}>Activity Progress</h1>
                </div>
                <div style={{ marginBottom: "20px", float: "left" }}>
                  <span style={{ fontSize: "20px" }}>Hello </span>
                  <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                    {learner},
                  </span>
                  <span style={{ fontSize: "20px" }}>
                    {" "}
                    please refer to your Activity Progress.
                  </span>
                </div>
              </Row>
              {/* <Row style={{ marginTop: "15px" }}>
              <Col span={12}>
                <h1>{currentActivity.module}</h1>
              </Col>
            </Row> */}

              <Row>
                <Col span={16}>
                  <Table
                    rowKey={record => record.id}
                    onRow={() => ({ style: { background: "#F5F5F5" } })}
                    onHeaderRow={() => ({ style: { background: "#B9E0E5" } })}
                    bordered
                    size="small"
                    dataSource={activitySummary}
                    loading={fetching}
                    columns={columns}
                    pagination={false}
                  />
                </Col>
                <Col span={8}>
                  <Row>
                    <PieChart
                      animate
                      animationDuration={500}
                      animationEasing="ease-out"
                      style={{
                        height: "300px"
                      }}
                      viewBoxSize={[100, 100]}
                      data={[
                        {
                          title: "Completed",
                          value: completed,
                          color: "#1890ff"
                        },
                        {
                          title: "In Progress",
                          value: inProgress,
                          color: "#fa8c16"
                        },
                        {
                          title: "Not Attempted",
                          value: notAttempted,
                          color: "#52c41a"
                        }
                      ]}
                    />
                  </Row>
                  <Row>
                    <Col>
                      <div
                        style={{
                          alignContent: "center",

                          justifyContent: "center",
                          display: "flex",
                          marginTop: "20px"
                        }}
                      >
                        <Table
                          style={{ width: "300px" }}
                          pagination={false}
                          bordered
                          size="small"
                          rowKey={row => row.status}
                          dataSource={[
                            {
                              status: "Completed",
                              value: completed,
                              color: "#1890ff"
                            },
                            {
                              status: "In Progress",
                              value: inProgress,
                              color: "#fa8c16"
                            },
                            {
                              status: "Not Attempted",
                              value: notAttempted,
                              color: "#52c41a"
                            }
                          ]}
                          loading={fetching}
                          columns={[
                            {
                              title: "Status",
                              dataIndex: "status",
                              key: "status"
                            },
                            {
                              title: "Value",
                              dataIndex: "value",
                              key: "value"
                            },
                            {
                              title: "Color",
                              dataIndex: "color",

                              render: (text, record) => (
                                <div
                                  style={{
                                    backgroundColor: text,
                                    height: "30px",
                                    width: "30px"
                                  }}
                                ></div>
                              )
                            }
                          ]}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          ) : (
            <Alert message={errorMessage} type="error" />
          )}
        </div>
        <div>
          <Row
            style={{
              padding: "20px",
              backgroundColor: "#ffdc00"
            }}
          >
            <Col span={12}>
              <ul>
                <li>
                  <a href="mailto:contact.pvce@unsw.edu.au">Contact us</a>
                </li>
                <li>
                  <a href="https://www.unsw.edu.au/privacy">Privacy Policy</a>
                </li>
                <li>
                  <a href="https://www.unsw.edu.au/copyright-disclaimer">
                    Copyright &amp; Disclaimer
                  </a>
                </li>
              </ul>
            </Col>

            <Col span={12}>
              <ul style={{ float: "right" }}>
                <li>
                  <div>Office of the Pro Vice-Chancellor (Education)</div>
                  <div>UNSW Sydney NSW 2052 Australia</div>
                  <div>Authorised by: Pro Vice-Chancellor (E), UNSW</div>
                  <div style={{ color: "rgba(117, 117, 117, 0.5)" }}>
                    {`Build date: ${preval`
                    const moment = require("moment");
                    module.exports = moment().format("DD/MM/YYYY");
                  `}`}
                  </div>
                </li>
              </ul>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default Summary;
