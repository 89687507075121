import React, { Component, Suspense, lazy } from "react";
import { Route, NavLink, Switch, Redirect } from "react-router-dom";
import { Layout, Menu, Button } from "antd";
import preval from "preval.macro";

import AppContext from "../AppContext";

const Course = lazy(() => import("./Course"));
const Activity = lazy(() => import("./Activity"));
const ActivityRecommendation = lazy(() => import("./ActivityRecommendation"));
const Settings = lazy(() => import("./Settings"));
const Notifications = lazy(() => import("./Notifications"));
const Logs = lazy(() => import("./Logs"));

class Dashboard extends Component {
  static contextType = AppContext;
  state = {
    fullScreenMode: false,
  };

  render() {
    const { location } = this.props;
    const { fullScreenMode } = this.state;
    const { logout } = this.context;

    const currentKey = location.pathname.split("/")[1].toLowerCase();

    return (
      <Layout style={{ height: "100%" }}>
        {!fullScreenMode ? (
          <Layout.Sider>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                backgroundColor: "#143a6b",
              }}
            >
              <img
                className="logo"
                src="https://cdn.teaching.unsw.edu.au/unswbranding/unsw_neg.png"
                alt="UNSW Logo"
                style={{ width: "80%", margin: 20 }}
              />

              <div style={{ flex: 1 }}>
                <Menu
                  theme="dark"
                  mode="inline"
                  defaultOpenKeys={["manage", "advance"]}
                  selectedKeys={[currentKey]}
                  style={{
                    backgroundColor: "#143a6b",
                  }}
                >
                  <Menu.SubMenu title="Manage Resources" key="manage">
                    <Menu.Item key="course">
                      <NavLink to="/course">Courses</NavLink>
                    </Menu.Item>
                    <Menu.Item key="activity">
                      <NavLink to="/activity">Activities</NavLink>
                    </Menu.Item>
                    <Menu.Item key="recommendation">
                      <NavLink to="/recommendation">Recommendation</NavLink>
                    </Menu.Item>
                  </Menu.SubMenu>
                  <Menu.SubMenu title="Advance" key="advance">
                    <Menu.Item key="notifications">
                      <NavLink to="/notifications">Notifications</NavLink>
                    </Menu.Item>
                    <Menu.Item key="logs">
                      <NavLink to="/logs">Logs & Stat</NavLink>
                    </Menu.Item>
                  </Menu.SubMenu>
                </Menu>
              </div>
              <Button
                style={{
                  background: "#001529",
                  color: "white",
                }}
                onClick={() => this.setState({ fullScreenMode: true })}
              >
                Full Screen Mode
              </Button>

              <div
                style={{
                  textAlign: "center",
                  color: "rgba(160, 160, 160, 0.75)",
                  margin: "20px 0",
                }}
              >
                {`Build date: ${preval`
                const moment = require("moment");
                module.exports = moment().format("DD/MM/YYYY");
              `}`}
              </div>
            </div>
          </Layout.Sider>
        ) : (
          ""
        )}
        <Layout>
          {!fullScreenMode ? (
            <Layout.Header
              style={{ background: "#fff", padding: "0 2px", margin: 10 }}
            >
              <h1 style={{ marginLeft: 20, textAlign: "center" }}>
                Learning to Teach Online
                <Button
                  type="warning"
                  icon="poweroff"
                  onClick={logout}
                  style={{
                    float: "right",
                    marginTop: "15px",
                    marginRight: "1%",
                  }}
                >
                  Logout
                </Button>
                <div
                  style={{
                    float: "right",
                    marginTop: "15px",
                    marginRight: "2%",
                  }}
                />
              </h1>
            </Layout.Header>
          ) : (
            <Button onClick={() => this.setState({ fullScreenMode: false })}>
              Exit Full Screen
            </Button>
          )}

          <Layout.Content
            style={{
              margin: "1px 16px 0",
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <div style={{ padding: 24, background: "#fff", flex: "1" }}>
              <Switch>
                <Redirect exact from="/" to="/course" />

                <Suspense fallback={<div>Loading...</div>}>
                  <Route path="/course" component={Course} />
                  <Route path="/activity" component={Activity} />
                  <Route
                    path="/recommendation"
                    component={ActivityRecommendation}
                  />
                  <Route path="/notifications" component={Notifications} />
                  <Route path="/logs" component={Logs} />
                </Suspense>
              </Switch>
            </div>
          </Layout.Content>
        </Layout>
      </Layout>
    );
  }
}

export default Dashboard;
