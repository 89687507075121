import React, { useRef } from "react";
import { Table, Button } from "antd";
import ReactToPrint from "react-to-print";

const PrintView = props => {
  const componentRef = useRef();

  return (
    <>
      <div style={{ display: "none" }}>
        <TableView {...props} ref={componentRef} />
      </div>

      <ReactToPrint
        key="print-component"
        trigger={() => <Button key="print-btn">Print All</Button>}
        content={() => componentRef.current}
      />
    </>
  );
};

class TableView extends React.Component {
  render() {
    const dataSource = this.props.activities.map(activity =>
      activity.activityrecommendation.map(recommendation => ({
        id: recommendation.id,
        activity: activity.title,
        title: recommendation.title,
        url: recommendation.url
      }))
    );
    const dataSource2 = this.props.generalRecommendations.map(
      recommendation => ({
        id: recommendation.id,
        activity: "General recommendation",
        title: recommendation.title,
        url: recommendation.url
      })
    );

    const flatDataSource = [...[].concat.apply([], dataSource), ...dataSource2];

    const columns = [
      {
        title: "Activity",
        dataIndex: "activity"
      },
      {
        title: "Title",
        dataIndex: "title",
        render: (text, record) => (
          <span>
            <a href={record.url}>{record.title}</a>
          </span>
        )
      }
    ];
    return (
      <>
        <div style={{ padding: "3px" }}>
          <Table
            title={() => "Personalised Resource Recommendations"}
            bordered
            dataSource={flatDataSource}
            columns={columns}
            rowKey={row => row.id}
            pagination={false}
          />
        </div>
      </>
    );
  }
}
export default PrintView;
