import React from "react";
import { Button, Result } from "antd";

class Forbidden extends React.Component {
  render() {
    // const { history } = this.props;

    return (
      <Result
        style={{ margin: "auto" }}
        status="403"
        title={<div>Ops...Access Denied!</div>}
        subTitle={
          <div
            style={{
              fontSize: "16px",
              marginLeft: "15vw",
              marginRight: "15vw",
            }}
          >
            <p style={{ color: "#ea4040" }}>
              Please note that a complete Coursera learner profile is required
              to set up your user profile on the LTTO app and your progress
              tracker. Before you access the LTTO app, please check and complete
              your Coursera Learner Profile from the user menu on the top right.
            </p>
            <p>
              If you are experiencing a connection issue, please try again
              later!
            </p>
          </div>
        }
        extra={
          <Button
            type="primary"
            size="large"
            onClick={() => {
              sessionStorage.clear();
              window.location.href =
                "https://www.coursera.org/learn/teach-online/home/welcome";
            }}
          >
            Back to home
          </Button>
        }
      />
      // <div
      //   style={{
      //     display: "flex",
      //     alignItems: "center",
      //     justifyContent: "center",
      //     flex: "auto",
      //     margin: "40px 0"
      //   }}
      // >
      //   <img src={vault} alt="Forbidden" />

      //   <div style={{ marginLeft: 30 }}>
      //     <h1
      //       style={{
      //         fontWeight: 600,
      //         fontSize: 48,
      //         color: "#434e59",
      //         marginBottom: 0
      //       }}
      //     >
      //       Access denied
      //     </h1>

      //     <div
      //       style={{ fontSize: 20, color: "rgba(0,0,0,.45)", marginBottom: 30 }}
      //     >
      //       Sorry, you don't have access to this page.
      //     </div>

      //     <Button
      //       type="primary"
      //       size="large"
      //       onClick={() => {
      //         sessionStorage.clear();
      //         history.push("/");
      //       }}
      //     >
      //       Back to home
      //     </Button>
      //   </div>
      // </div>
    );
  }
}

export default Forbidden;
